var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "merchantadd-container position-relative" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "addForm",
            attrs: {
              "label-width": "120px",
              model: _vm.merchantList,
              rules: _vm.rules,
              inline: true,
              "hide-required-asterisk": true,
            },
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "售后单号", prop: "as_no" } },
                          [_c("div", [_vm._v(_vm._s(_vm.merchantList.as_no))])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "订单号", prop: "order_no" } },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.merchantList.order_no)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "客户姓名", prop: "merchantPhone" },
                          },
                          [
                            _vm.merchantList.order &&
                            _vm.merchantList.order.member
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.merchantList.order.member.fullname
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户电话", prop: "storeType" } },
                          [
                            _vm.merchantList.order &&
                            _vm.merchantList.order.member
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.merchantList.order.member.mobile
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "城市仓", prop: "warehouseId" } },
                          [
                            _vm.merchantList.order &&
                            _vm.merchantList.order.store_delivery &&
                            _vm.merchantList.order.store_delivery.logistics
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.merchantList.order.store_delivery
                                          .logistics.name
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "自提点信息", prop: "carLicense" },
                          },
                          [
                            _vm.merchantList.order &&
                            _vm.merchantList.order.store_delivery
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.merchantList.order.store_delivery
                                          .name
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "auto-table-flex mb-30" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "goodsTable",
            attrs: {
              data: _vm.goodsData,
              border: true,
              "span-method": _vm.arraySpanMethod,
              "show-summary": false,
              "summary-method": _vm.goodsCount,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "goodsName",
                width: "130",
                align: "center",
                label: "主图",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.$index == 1 || scope.$index % 2 !== 0
                        ? _c("div", { staticClass: "d-flex a-center" }, [
                            _c("div", { staticClass: "d-flex a-center mr10" }, [
                              _c("div", [_vm._v("商品金额：")]),
                              _c(
                                "div",
                                { staticClass: "input-width" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled: _vm.detailDisabled,
                                        placeholder: "输入商品金额",
                                        clearable: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index,
                                            scope.row.refund_amt,
                                            "refund_amt"
                                          )
                                        },
                                        input: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index,
                                            scope.row.refund_amt,
                                            "refund_amt"
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.refund_amt,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "refund_amt", $$v)
                                        },
                                        expression: "scope.row.refund_amt",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            color: "#333333",
                                          },
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v(" 元 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "d-flex a-center mr10" }, [
                              _c("div", [_vm._v("运费：")]),
                              _c(
                                "div",
                                { staticClass: "input-width" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled: _vm.detailDisabled,
                                        placeholder: "输入运费",
                                        clearable: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index
                                          )
                                        },
                                        input: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.freight_amt,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "freight_amt",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.freight_amt",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            color: "#333333",
                                          },
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v(" 元 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "d-flex a-center mr10" }, [
                              _c("div", [_vm._v("服务费：")]),
                              _c(
                                "div",
                                { staticClass: "input-width" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled: _vm.detailDisabled,
                                        placeholder: "输入服务费",
                                        clearable: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index
                                          )
                                        },
                                        input: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.purchase_amt,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "purchase_amt",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.purchase_amt",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            color: "#333333",
                                          },
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v(" 元 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "d-flex a-center mr10" }, [
                              _c("div", [_vm._v("金融手续费：")]),
                              _c(
                                "div",
                                { staticClass: "input-width" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled: _vm.detailDisabled,
                                        placeholder: "输入金融手续费",
                                        clearable: "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index
                                          )
                                        },
                                        input: function ($event) {
                                          return _vm.surchargeChange(
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.finance_amt,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "finance_amt",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.finance_amt",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            color: "#333333",
                                          },
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v(" 元 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "d-flex a-center" }, [
                              _c("div", [_vm._v("合计：")]),
                              _c(
                                "div",
                                { staticClass: "input-width" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled: true,
                                        placeholder: "合计金额",
                                        clearable: "",
                                      },
                                      model: {
                                        value: scope.row.all,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "all", $$v)
                                        },
                                        expression: "scope.row.all",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            color: "#333333",
                                          },
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v(" 元 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _c(
                            "div",
                            [
                              scope.row.product
                                ? [
                                    _c("el-image", {
                                      staticClass: "kv pointer",
                                      attrs: {
                                        src: scope.row.product.kv,
                                        "preview-src-list": [
                                          scope.row.product.kv,
                                        ],
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "title", align: "center", label: "商品信息" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.product
                        ? _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("div", { staticClass: "border-b pb" }, [
                              _vm._v(
                                " 商品名称：" +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.product.title)
                                  ) +
                                  " (" +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.product.sku_code)
                                  ) +
                                  ") "
                              ),
                            ]),
                            _c("div", { staticClass: "border-b py" }, [
                              _vm._v(
                                " 商品价格：" +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      (scope.row.product.price * 10000) /
                                        100 /
                                        10000
                                    )
                                  ) +
                                  "元 "
                              ),
                            ]),
                            _c("div", { staticClass: "border-b py" }, [
                              _vm._v(
                                " 毛重：" +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      scope.row.product.gross_weight
                                    )
                                  ) +
                                  "斤 "
                              ),
                            ]),
                            _c("div", { staticClass: "border-b py" }, [
                              _vm._v(
                                " 毛单价：" +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      (((scope.row.product.price * 1000) /
                                        scope.row.product.gross_weight) *
                                        1000) /
                                        100 /
                                        1000000
                                    )
                                  ) +
                                  "元 "
                              ),
                            ]),
                            _c("div", { staticClass: "pt" }, [
                              _vm._v(
                                " 购买数量：" +
                                  _vm._s(_vm.$empty.empty(scope.row.sale_qty)) +
                                  "个 "
                              ),
                            ]),
                            _c("div", { staticClass: "pt" }, [
                              _vm._v(
                                " 售后条件：" +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.product.as_cond)
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        : _c("div", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "title",
                align: "center",
                width: "150",
                label: "费用信息(元)",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.sale_freight_amt
                        ? _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("div", { staticClass: "border-b pb" }, [
                              _vm._v(
                                " 运费：" +
                                  _vm._s(scope.row.sale_freight_amt) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "border-b py" }, [
                              _vm._v(
                                " 服务费：" +
                                  _vm._s(scope.row.sale_purchase_amt) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "pt" }, [
                              _vm._v(
                                "金融手续费：" +
                                  _vm._s(scope.row.sale_finance_amt)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "reason", align: "center", label: "售后原因" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$empty.empty(scope.row.reason))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product_weight",
                width: "110",
                align: "center",
                label: "报损重量(斤)",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$empty.empty(scope.row.product_weight))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "desc", align: "center", label: "问题描述" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.desc))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "attach_pic", label: "证据" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.attach_pic || scope.row.attach_video
                        ? [
                            _vm._l(
                              scope.row.attach_pic.split(","),
                              function (img, idx) {
                                return scope.row.attach_pic
                                  ? _c("el-image", {
                                      key: idx,
                                      staticClass: "kv pointer mr10",
                                      attrs: {
                                        src: img,
                                        "preview-src-list": [img],
                                      },
                                    })
                                  : _vm._e()
                              }
                            ),
                            scope.row.attach_video
                              ? _c("video", {
                                  ref: "videoPlayer",
                                  staticClass: "video pointer",
                                  attrs: {
                                    src: scope.row.attach_video,
                                    autoplay: false,
                                    controls: true,
                                  },
                                })
                              : _vm._e(),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
        _c(
          "el-table",
          {
            ref: "goodsTable",
            attrs: {
              "show-header": false,
              data: _vm.sumData,
              border: true,
              "span-method": _vm.arraySpanMethod1,
              "cell-style": { color: "#333333", background: "#EFF6FF" },
            },
          },
          [
            _c("el-table-column", { attrs: { prop: "key", align: "center" } }),
            _c("el-table-column", { attrs: { prop: "total" } }),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
            _c("el-table-column"),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "d-flex a-center remark-wrapper",
        staticStyle: { "padding-bottom": "50x" },
      },
      [
        _c("div", { staticStyle: { "white-space": "nowrap" } }, [
          _vm._v("备注："),
        ]),
        _c("el-input", {
          attrs: {
            type: "textarea",
            disabled: _vm.detailDisabled,
            rows: 2,
            placeholder: "请输入备注",
          },
          model: {
            value: _vm.merchantList.remark,
            callback: function ($$v) {
              _vm.$set(_vm.merchantList, "remark", $$v)
            },
            expression: "merchantList.remark",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "fy1 fy2",
        staticStyle: { position: "fixed", bottom: "0", right: "0" },
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("返回")]
        ),
        _vm.state == 0
          ? _c(
              "el-button",
              { attrs: { type: "success" }, on: { click: _vm.sureConfirm } },
              [_vm._v("确定")]
            )
          : _vm._e(),
        _vm.state == 0
          ? _c(
              "span",
              { staticStyle: { color: "#e66f34", "margin-left": "10px" } },
              [_vm._v("注：请谨慎填写报损金额，确认后将直接退款给客户！！！")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showImage,
          expression: "showImage",
        },
      ],
      staticClass: "shadow",
      on: {
        click: function ($event) {
          _vm.showImage = false
        },
      },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImage,
            expression: "showImage",
          },
        ],
        staticClass: "bigImage",
      },
      [
        _c("img", { attrs: { src: _vm.bigImage, alt: "" } }),
        _c("i", {
          staticClass: "el-icon el-icon-close bigClose",
          on: {
            click: function ($event) {
              _vm.showImage = false
            },
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }